<template>
    <div class="home selection:bg-slate-300">
        <!-- Video Hero -->
        <section
            class="page w-full h-screen overflow-hidden relative bg-none after:w-full after:h-full after:block after:absolute">
            <div class="w-full h-full flex flex-col justify-center items-center absolute overflow-hidden z-0">
                <video playsinline loop muted poster="@/assets/images/poster.jpg"
                    id="bgVideoTein" class="w-full h-full object-cover">
                    <source
                        src="video/tein.mp4" 
                        type="video/mp4">
                    <!-- <source
                        src="video/tein.webm" 
                        type="video/webm"> -->
                </video>
            </div>

            <!-- Color Words -->
            <div id="keyWords" class="w-screen h-screen absolute z-1 flex flex-col justify-center items-center">
                <div class="ml4">
                    <span class="letters letters-1 fancy f-1">Tecnología</span>
                    <span class="letters letters-2 fancy f-2">Experiencia</span>
                    <span class="letters letters-3 fancy f-3">Transformación</span>
                    <span class="letters letters-4 fancy f-4">Innovación</span>
                </div>

            </div>

            <div class="container absolute bottom-10 left-0 right-0 m-auto z-10 flex flex-col lg:flex-row justify-center items-center lg:justify-between lg:items-center">
                <button id="btnVideoFunctions" @click="videoButtons" class="inline-flex items-center mb-20 lg:mb-0 text-white font-bold ">
                    <span class="relative">
                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-orangetein opacity-75"></span>
                        <span class="relative inline-flex justify-center items-center rounded-full h-10 w-10 lg:h-14 lg:w-14 bg-orangetein">
                            <span id="ico-control" class="material-icons-outlined inline-block !text-2xl lg:!text-3xl">pause</span>
                        </span>
                    </span>    
                    <span id="text-control" class="inline-block pl-4">Pausar Video</span>    
                </button>

                <a href="#solutions" id="btnScroll" class="ml8">
                    <span class="letters-container">
                        <span class="letters letters-left">Scroll</span>
                        <!-- <span class="letters bang">!</span> -->
                    </span>
                    <span class="circle circle-white"></span>
                    <span class="circle circle-dark"></span>
                    <span class="circle circle-container"><span class="circle circle-dark-dashed"></span></span>
                </a>
            </div>

        </section>

        <section id="solutions" class="pb-12 md:py-20 lg:py-28 relative">
                <div class="squares hidden w-full md:h-full mt-20 md:mt-0 md:absolute md:top-0 md:left-0 md:flex -z-1 order-last md:order-1">
                    <div class="outerHeight mt-auto mb-20 lg:mb-28">
                        <span data-x="270" class="el bg-greentein"></span>
                        <span data-x="80" class="el bg-orangetein"></span>
                        <span data-x="470" class="el bg-limatein"></span>
                        <span data-x="340" class="el bg-redtein"></span>
                        <span data-x="470" class="el bg-lightorangetein"></span>
                        <span data-x="570" class="el bg-bluetein"></span>
                    </div>
                </div>
                
                <ServicesCarousel></ServicesCarousel>
        </section>    
            
            <!-- Vission and Mission -->
            <section id="vission-mission" class="w-full px-8 mt-8 md:mt-12 flex  md:flex-row justify-center">
                <div class="md:w-10/12">
                    <!-- Vission -->
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:min-h-[460px] fade-in scale">
                        <!-- Image -->
                        <div class="">
                            <img src="@/assets/images/vission.jpg" alt="Nuestra Visión" class="w-full h-full object-cover">
                        </div>

                        <!-- Description -->
                        <div class="bg-orange-100 p-6 md:py-14 md:px-10 lg:p-16  box-border flex flex-col justify-center items-center text-center">
                            <h2 class="text-orangetein mb-5 font-black">Visión</h2>
                            <p class="text-orangetein font-medium text-base">Ser parte del ecosistema tecnológico, generando tendencias disruptivas, brindando servicios con un alto valor agregado que consoliden una imagen de marca referente en aspectos de consultoría tecnológica.</p>
                        </div>
                    </div>

                    <!-- Mission -->
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:min-h-[460px] fade-in scale">
                        <!-- Description -->
                        <div class="bg-lime-100 p-12 md:py-14 md:px-10 lg:p-16  box-border flex flex-col justify-center items-center text-center">
                            <h2 class="text-greentein mb-5 font-black">Misión</h2>
                            <p class="text-greentein font-medium text-base">Liderar equipos de trabajos interdisciplinarios que transformen la experiencia en la interacción y procesos, proveyendo soluciones de negocios tecnológicas que generen un valor agregado creciente en nuestros clientes internos y externos, socios de negocios y proveedores.</p>
                        </div>
                        <!-- Image -->
                        <div class="order-first md:order-2">
                            <img src="@/assets/images/mission.jpg" alt="Nuestra Misión" class="w-full h-full object-cover">
                        </div>
                    </div>

                    <!-- Values -->
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:min-h-[460px] fade-in scale">
                        <!-- Image -->
                        <div class="">
                            <img src="@/assets/images/valores.jpg" alt="Nuestros Valores" class="w-full h-full object-cover">
                        </div>

                        <!-- Description -->
                        <div class="bg-sky-100 p-12 md:py-14 md:px-10 lg:p-16  box-border flex flex-col justify-center items-center text-center">
                            <h2 class="text-bluetein mb-5 font-black">Valores</h2>
                            <p class="text-bluetein font-medium text-base">En <span class="font-rubik font-bold">tein</span> asumimos la integridad, el compromiso, la sustentabilidad, la flexibilidad, la excelencia, el trabajo en equipo y el espíritu emprendedor, como los valores que nos distinguen. Estos valores que guían nuestro accionar son la base sobre la cual construimos nuestras actividades cotidianas.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                    <!-- CTA Manual -->
                <div class="container  my-12 md:my-28 md:flex md:justify-center">
                    <div class="md:w-10/12 lg:w-8/12">
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-9 md:gap-11 md:place-items-center">
                            <div class="img-animated reduced mod-img mod-imgmbl fade-in scale -hue-rotate-60">
                                <img src="@/assets/images/codigo-conducta.jpg" alt="Manual de Código de Conducta" class="md:h-full md:object-cover">
                            </div>
                            
                            <div>
                                <h3 class="fade-in y">Código de Conducta</h3>
                                <p class="fade-in y mb-8">Nuestro Código de Conducta son los valores que guían nuestro accionar y la base sobre la cual construimos nuestras acciones cotidianas.</p>
                                <router-link :to="{ name: 'codigo-conducta' }">
                                    <button class="fade-in y btn-theme-strong inline-block">Código de Conducta</button>
                                </router-link>
                                <!-- <a href="/codigo-conducta" class="fade-in y btn-theme-strong inline-block">Código de Conducta</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Clientes -->
            <section class="container mt-11 md:mt-8 lg:mt-14">
                <div class="flex flex-col justify-center items-center">
                    <div class="lg:w-10/12 text-center py-8 md:py-10 lg:py-14 fade-in y">
                        <h2 class="main-title">Clientes</h2>
                        <p class="text-base md:text-sm lg:text-lg mb-0">Cada nueva idea, pensamiento, procesos de innovación, y fundamentalmente nuestro crecimiento continuo es
                            gracias al espacio de confianza de nuestros clientes, nuestra forma de agradecerles es manifestando la
                            pasión que sentimos en ser parte del mismo equipo.</p>
                    </div>

                </div>
            </section>
            <!-- Full Carousel -->
            <section class="fade-in">
                <div class="slider-logos">
                    <Vue3Marquee id="clientes-carousel"
                    :speed="18000"
                    :gradient="true"
                    :gradientColor="[255, 255, 255]"
                    :gradientWidth="`20%`"
                    :pauseOnHover="true"
                    >
                        <img src="@/assets/images/clientes/acc.png" alt="ACC">
                        <img src="@/assets/images/clientes/AirON.png" alt="AirOn">
                        <img src="@/assets/images/clientes/amv.png" alt="AMV">
                        <img src="@/assets/images/clientes/atento.png" alt="Atento">
                        <img src="@/assets/images/clientes/cat.png" alt="CAT">
                        <img src="@/assets/images/clientes/cristamine.png" alt="Cristamine">
                        <img src="@/assets/images/clientes/csc.png" alt="CSC">
                        <img src="@/assets/images/clientes/Deelo.png" alt="Deelo">
                        <img src="@/assets/images/clientes/falabella.png" alt="Falabella">
                        <img src="@/assets/images/clientes/midav.png" alt="Midav">
                        <img src="@/assets/images/clientes/movistar.png" alt="Movistar">
                        <img src="@/assets/images/clientes/municipalidad-de-pilar.png" alt="Municipalidad de Pilar">
                        <img src="@/assets/images/clientes/tasksolutions.png" alt="Task Solutions">
                        <img src="@/assets/images/clientes/telefonica.png" alt="Telefónica">
                        <img src="@/assets/images/clientes/trevor.png" alt="Trevor">
                        <img src="@/assets/images/clientes/vfs.png" alt="VFS">
                        <img src="@/assets/images/clientes/xl-extra-large.png" alt="XL Extra Large">
                        <img src="@/assets/images/clientes/muni-berisso.jpg" alt="Municipalidad de Berisso">
                        <img src="@/assets/images/clientes/muni-santafe.png" alt="Municipalidad de Santa Fe">
                    </Vue3Marquee>
                </div>
            </section>


            <!-- Tecnología y Soluciones -->
            <section id="mod-alianzas" class="w-full py-12 md:py-20 lg:py-28 md:flex md:flex-col md:justify-center fade-in y">
                <div class="container flex flex-col items-center">
                    <div class="lg:w-10/12">
                        <h2 class="main-title text-center">Tecnologías</h2>
                        <p class="text-base md:text-sm lg:text-lg text-center">Conjugando las diferentes unidades de servicios profesionales de <span class="text-tein">tein</span> con el grado de innovación de las soluciones tecnológicas de nuestros socios de negocios logramos brindar una oferta completa para todos nuestros clientes.</p>
                    </div>
                </div>    
                <div class="slider-logos md:mt-8">
                    <Vue3Marquee id="tech-carousel"
                    :speed="18000"
                    :gradient="true"
                    :gradientColor="[255, 255, 255]"
                    :gradientWidth="`20%`"
                    :pauseOnHover="true"
                    >
                    <img src="@/assets/images/alianzas/hikVision.png" alt="hikVision">
                    <img src="@/assets/images/alianzas/janusCam.jpg" alt="Janus Cam">
                    <img src="@/assets/images/alianzas/sophos.jpg" alt="Sophos">
                    <img src="@/assets/images/alianzas/mcAfee.png" alt="McAffe">
                    <img src="@/assets/images/alianzas/olos-logo.png" alt="Olos">
                    <img src="@/assets/images/alianzas/mikrotik.png" alt="Mikrotik">
                    <img src="@/assets/images/alianzas/ubiquiti.png" alt="Ubiquite">
                    <img src="@/assets/images/alianzas/cambium.jpg" alt="Cambium Networks">
                    <img src="@/assets/images/alianzas/enGenius.jpg" alt="EnGenius">
                    <img src="@/assets/images/alianzas/microsoft.png" alt="Microsoft">
                    <img src="@/assets/images/alianzas/linux-logo.png" alt="Linux">
                    <img src="@/assets/images/alianzas/vmware.jpg" alt="VMWare">
                    <img src="@/assets/images/alianzas/dell.png" alt="Dell">
                    <img src="@/assets/images/alianzas/qlik.png" alt="Qlik">
                    <img src="@/assets/images/alianzas/verint.png" alt="Verint">
                    <img src="@/assets/images/alianzas/freshworks.png" alt="Freshworks">
                    <img src="@/assets/images/alianzas/maltego.png" alt="Maltego">
                    </Vue3Marquee>
                </div>
            </section>
    </div>
</template>

<script>
import anime from 'animejs';
import  {Vue3Marquee}  from 'vue3-marquee';
import 'vue3-marquee/dist/style.css';
import ServicesCarousel from "@/components/ServicesCarousel.vue";

require('waypoints/lib/noframework.waypoints.js');

export default {
    name: 'HomePage',
    data() {
        return {
            scrollPosition: 0,
    }
    },
    created() {

    },

    components: {
        Vue3Marquee,
        ServicesCarousel

    },

    mounted()  {
            
    this.onHeroAnimation();
    this.transitionsOnScroll();
    this.onSolutions();
    this.videoAutoPlay();
    document.body.classList.add('homepage');
    window.scrollTo(0, 0);
},

    methods: {
    go() {
        anime({                                      
        targets: this.$refs.square,
        translateX: 500
        });
    },

    getScrollPercent() {
        var h = document.documentElement, 
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight'
    return (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100
    },

    transitionsOnScroll() {
        let elem = this.$el.querySelectorAll('.fade-in, .fade-in-slide')

        elem.forEach(x => {
        this.waypoint = new window.Waypoint({
        element: x,
        handler: () => {
            x.classList.add('visible')
            },
            offset: '80%',
        })
    })
    },
    
    onSolutions() {
        let squaresBg = anime.timeline({
        loop: false,
        delay: 700,
        opacity: [0, 1],
        autoplay: false
        });
        squaresBg.add({
            targets: '.outerHeight .el',
            opacity: [0, 1],
            translateX: function(el) {
                return el.getAttribute('data-x');
            },
            translateY: function(el, i) {
                return 70 + (-5 * i);
            },
            scale: function(el, i, l) {
                return (l - i) + .15;
            },
            rotate: function() { return anime.random(-360, 360); },
            borderRadius: function() { return ['50%', anime.random(10, 25) + '%']; },
            duration: function() { return anime.random(1200, 1800); },
            delay: function() { return anime.random(0, 400); },
            loop: false,
        });  

        // document.addEventListener("DOMContentLoaded", function() {
            var waypoint = new window.Waypoint({
            element: document.getElementById('solutions'),
            // handler: function(direction) {
            // if(direction=="down"){
            // squaresBg.play();
            // }
            // else {
            // squaresBg.restart();
            // }
            // }, 
            // offset: '50%' 
            handler: () => {
                squaresBg.play();
                },
                offset: '80%',
            
            }) 
        // });
    },
    
    onServices(){
        let titlePos = window.scrollY + document.querySelector('#titleLocation').getBoundingClientRect().left + 'px';
        let locations = document.querySelector('.wrapper-locations');
        locations.style.marginLeft = titlePos;
        },

    videoButtons() {
        let video = document.getElementById("bgVideoTein");
        let ico = document.getElementById("ico-control");
        let text = document.getElementById("text-control");
        if (video.paused) {
        video.play();
        ico.innerHTML = 'pause';
        text.innerHTML = 'Pausar Video';
        } else {
        video.pause();
        ico.innerHTML = 'play_arrow';
        text.innerHTML = 'Play Video';
        }
    },    
    videoAutoPlay() {
        var video = document.getElementById("bgVideoTein");
        video.addEventListener("canplay", function() {
        setTimeout(function() {
            video.play();
        }, 0);
        });
    }, 
        onHeroAnimation() {

            // Hero Animation
            var heroAnimation = anime.timeline({
                loop: false,
                autoplay: false
            });
            heroAnimation.add({
                targets: '#brandWhite',
                opacity: [0,1],
                easing: 'easeInOutQuad',
                duration: 1000,
            }).add({
                targets: '.navegation .split-text',
                opacity: [0,1],
                easing: "easeOutExpo",
                duration: 1200,
                delay: anime.stagger(300)
            }).add({
                targets: '.ico-drop',
                opacity: [0,1],
            }).add({
                targets: '.secondary-nav .btn-waves',
                opacity: [0,1],
                easing: "easeInOutQuad",
            }, '-=1900').add({
                targets: '#btnVideoFunctions',
                opacity: [0,1],
            }).add({
                targets: '.ml8 .circle-white',
                scale: [0, 3],
                opacity: [1, 0],
                easing: "easeInOutExpo",
                rotateZ: 360,
                duration: 1100
            }).add({
                targets: '.ml8 .circle-container',
                scale: [0, 1],
                duration: 1100,
                easing: "easeInOutExpo",
                offset: '-=1000'
            }).add({
                targets: '.ml8 .circle-dark',
                scale: [0, 1],
                duration: 1100,
                easing: "easeOutExpo",
                offset: '-=600'
            }).add({
                targets: '.ml8 .letters-left',
                scale: [0, 1],
                duration: 1200,
                offset: '-=550'
            });

            // Keywords
            var ml4 = {};
            ml4.opacityIn = [0,1];
            ml4.scaleIn = [0.2, 1];
            ml4.scaleOut = 3;
            ml4.durationIn = 800;
            ml4.durationOut = 600;
            ml4.delay = 300;

            var keyWordsAnimation = anime.timeline({
                loop: true,
                autoplay: false,
                delay: 2700,
            });
        
            keyWordsAnimation.add({
                targets: '.ml4 .letters-1',
                opacity: ml4.opacityIn,
                scale: ml4.scaleIn,
                duration: ml4.durationIn
            }).add({
                targets: '.ml4 .letters-1',
                opacity: 0,
                scale: ml4.scaleOut,
                duration: ml4.durationOut,
                easing: "easeInExpo",
                delay: ml4.delay
            }).add({
                targets: '.ml4 .letters-2',
                opacity: ml4.opacityIn,
                scale: ml4.scaleIn,
                duration: ml4.durationIn
            }).add({
                targets: '.ml4 .letters-2',
                opacity: 0,
                scale: ml4.scaleOut,
                duration: ml4.durationOut,
                easing: "easeInExpo",
                delay: ml4.delay
            }).add({
                targets: '.ml4 .letters-3',
                opacity: ml4.opacityIn,
                scale: ml4.scaleIn,
                duration: ml4.durationIn
            }).add({
                targets: '.ml4 .letters-3',
                opacity: 0,
                scale: ml4.scaleOut,
                duration: ml4.durationOut,
                easing: "easeInExpo",
                delay: ml4.delay
            }).add({
                targets: '.ml4 .letters-4',
                opacity: ml4.opacityIn,
                scale: ml4.scaleIn,
                duration: ml4.durationIn
            }).add({
                targets: '.ml4 .letters-4',
                opacity: 0,
                scale: ml4.scaleOut,
                duration: ml4.durationOut,
                easing: "easeInExpo",
                delay: ml4.delay
            });


            // Animations playlist
            heroAnimation.play();
            keyWordsAnimation.play();

            anime({
                targets: '.ml8 .circle-dark-dashed',
                rotateZ: 360,
                duration: 8000,
                easing: "linear",
                loop: true
            });
        },
},
    

setup() {

    return { 
        
    };
},

    unmounted () {
    document.body.classList.remove('homepage');
}

}

</script>
