<template>     
    <div class="md:container md:flex md:flex-col md:justify-center md:items-center overflow-hidden">
        <!-- light -->
        <div class="mod-carouselServices">
            <div class="wrapper-thumbs">
                    <swiper
                    :modules="module"
                    :pagination="{ clickable: true }"
                    :direction="'horizontal'"
                    :slidesPerView="1"
                    :watchSlidesVisibility="true"
                    :watch-slides-progress="true"
                    @swiper="setThumbsSwiper"
                    :autoplay="{
                        delay: 12500,
                        disableOnInteraction: false,
                    }"
                    
                    :effect="fade"
                    :loop="false"
                    :spaceBetween="10"
                    :breakpoints="{ 
                        768:{ 
                            direction: 'vertical',
                            slidesPerView: 4,
                            pagination: false,
                            height: 600
                        } 
                        }"
                    :updateOnWindowResize="true"
                    class="serviceThumb"
                    
                >

                    <swiper-slide>
                        <div  class="solutionItem">
                            <div class="wrapper-title">
                                <span class="number">01</span>
                                <div class="md:pl-4 grow">
                                    <router-link to="/soluciones/servicios-profesionales" class="btn-more">
                                        <span class="title">Servicios  Profesionales</span>
                                        <button class="hidden md:flex w-full mt-4 justify-between items-center">
                                            <span class="uppercase font-medium tracking-wider text-xs">Explorar</span>
                                            <span class="ico w-6 h-6 ml-4 rounded-full bg-gradient-to-r from-greentein to-orangetein text-white flex justify-center items-center">
                                            <span class="material-icons-outlined !text-lg ">arrow_outward</span>
                                            </span>
                                        </button> 
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="solutionItem">
                            <div class="wrapper-title">
                                <span class="number">02</span>
                                <div class="md:pl-4 grow">
                                    <router-link to="/soluciones/optimizacion-contact-center" class="btn-more">
                                        <span class="title">Optimización del Contact Center</span>
                                        <button class="hidden md:flex w-full mt-4 justify-between items-center">
                                            <span class="uppercase font-medium tracking-wider text-xs">Explorar</span>
                                            <span class="ico w-6 h-6 ml-4 rounded-full bg-gradient-to-r from-greentein to-orangetein text-white flex justify-center items-center">
                                            <span class="material-icons-outlined !text-lg ">arrow_outward</span>
                                            </span>
                                        </button> 
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="solutionItem">
                            <div class="wrapper-title">
                                <span class="number">03</span>
                                <div class="md:pl-4 grow">
                                    <router-link to="/soluciones/seguridad" class="btn-more">
                                        <span class="title">Seguridad</span>
                                        <button class="hidden md:flex w-full mt-4 justify-between items-center">
                                            <span class="uppercase font-medium tracking-wider text-xs">Explorar</span>
                                            <span class="ico w-6 h-6 ml-4 rounded-full bg-gradient-to-r from-greentein to-orangetein text-white flex justify-center items-center">
                                            <span class="material-icons-outlined !text-lg ">arrow_outward</span>
                                            </span>
                                        </button> 
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="solutionItem">
                            <div class="wrapper-title">
                                <span class="number">04</span>
                                <div class="md:pl-4 grow">
                                    <router-link to="/soluciones/analisis-integracion-datos" class="btn-more">
                                        <span class="title">Análisis e Integración de Datos</span>
                                        <button class="hidden md:flex w-full mt-4 justify-between items-center">
                                            <span class="uppercase font-medium tracking-wider text-xs">Explorar</span>
                                            <span class="ico w-6 h-6 ml-4 rounded-full bg-gradient-to-r from-greentein to-orangetein text-white flex justify-center items-center">
                                            <span class="material-icons-outlined !text-lg ">arrow_outward</span>
                                            </span>
                                        </button> 
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>

            <div class="wrapper-main">
                    <swiper
                    :autoplay="{
                        delay: 12500,
                        disableOnInteraction: false,
                    }"
                    :effect="fade"
                    :loop="false"
                    
                    :slidesPerView="1"
                    :space-between="10"
                    navigation
                    :autoHeight= "false"
                    :modules="module"
                    :thumbs="{ swiper: thumbsSwiper }"
                    class="serviceMain"
                    
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    >
                    <swiper-slide>
                        <div class="wrapper-description">
                            <h2 class="main-title black">Soluciones Profesionales</h2>
                            <p class="text">Encuentre el servicio profesional a la medida de sus necesidades.</p>
                            <router-link to="/soluciones/servicios-profesionales" class="btn-more">
                                <button class="btn-link">
                                    <span class="uppercase font-medium tracking-wider text-lg">Explorar</span>
                                    <span class="ico w-6 h-6 ml-4 rounded-full bg-gradient-to-r from-greentein to-orangetein text-white flex justify-center items-center">
                                    <span class="material-icons-outlined !text-lg ">arrow_outward</span>
                                    </span>
                                </button> 
                            </router-link>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="wrapper-description">
                            <h2 class="main-title black">Soluciones para su Contact Center</h2>
                            <p class="text">Logre una experiencia integrada en la gestión a clientes de su empresa.</p>
                            <router-link to="/soluciones/optimizacion-contact-center" class="btn-more">
                                <button class="btn-link">
                                    <span class="uppercase font-medium tracking-wider text-lg">Explorar</span>
                                    <span class="ico w-6 h-6 ml-4 rounded-full bg-gradient-to-r from-greentein to-orangetein text-white flex justify-center items-center">
                                    <span class="material-icons-outlined !text-lg ">arrow_outward</span>
                                    </span>
                                </button> 
                            </router-link>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="wrapper-description">
                            <h2 class="main-title black">Soluciones de Seguridad</h2>
                            <p class="text">Conjugando tecnología de primera línea podemos otorgar un gran valor a los procesos de seguridad de su empresa.</p>
                            <router-link to="/soluciones/seguridad" class="btn-more">
                                <button class="btn-link">
                                    <span class="uppercase font-medium tracking-wider text-lg">Explorar</span>
                                    <span class="ico w-6 h-6 ml-4 rounded-full bg-gradient-to-r from-greentein to-orangetein text-white flex justify-center items-center">
                                    <span class="material-icons-outlined !text-lg ">arrow_outward</span>
                                    </span>
                                </button> 
                            </router-link>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="wrapper-description">
                            <h2 class="main-title black">Soluciones aplicadas a Datos</h2>
                            <p class="text">Entrega de datos en tiempo real y automatización del proceso a cualquier nube.</p>
                            <router-link to="/soluciones/analisis-integracion-datos" class="btn-more">
                                <button class="btn-link">
                                    <span class="uppercase font-medium tracking-wider text-lg">Explorar</span>
                                    <span class="ico w-6 h-6 ml-4 rounded-full bg-gradient-to-r from-greentein to-orangetein text-white flex justify-center items-center">
                                    <span class="material-icons-outlined !text-lg ">arrow_outward</span>
                                    </span>
                                </button> 
                            </router-link>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            
        </div>
    </div>

</template>

<script>

import SwiperCore, { Navigation, Pagination, Thumbs, EffectFade, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';

import service01 from '@/assets/images/menu-serviciosprofesionales.jpg';
import service02 from '@/assets/images/menu-callcenter.jpg';
import service03 from '@/assets/images/menu-seguridad.jpg';
import service04 from '@/assets/images/menu-integraciondata.jpg';

SwiperCore.use([Navigation, Pagination, Thumbs, EffectFade, Autoplay]);

export default {
    name: 'ServicesCarousel',
    data() {
        return {
            thumbsSwiper: null,
            service01Bg: service01,
            service02Bg: service02,
            service03Bg: service03,
            service04Bg: service04,
        };
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    mounted() {
    },
    
    methods: {
        setThumbsSwiper(swiper) {
        this.thumbsSwiper = swiper;
        },
        onSwiper(swiper) {

        },
        onSlideChange() {
        },
        squaresCarousel() {
            
        }

    }
}
</script>