import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/components/HomePage.vue'
//import htmlize from 'vue-htmlize'
//import NotFound from '@/components/globals/NotFound.vue'


const router = createRouter({
    //base: process.env.BASE_URL,
    history: createWebHistory(),
    routes: [
        { 
            path: '/',
            name: 'inicio',
            component: HomePage,
            meta: {
                title: 'Inicio', 
                description: 'TEIN brinda servicios de tecnología e infraestructura con soporte  a lo largo de todo el proceso de implementación, administración y soporte de una nueva tecnología.',
                progress: {
                    func: [
                        { call: "color", modifier: "temp", argument: "#F58228" },
                        { call: "fail", modifier: "temp", argument: "#F04138" },
                        { call: "location", modifier: "temp", argument: "top" },
                        {
                        call: "transition",
                        modifier: "temp",
                        argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                        },
                    ],
                    },
            }
        },
        {
            path: '/sobre-nosotros',
            name: 'sobre-nosotros',
            component: () => import('@/components/AboutPage.vue'),
            meta: {
                title: 'Sobre Nosotros',
                progress: {
                    func: [
                        { call: "color", modifier: "temp", argument: "#F58228" },
                        { call: "fail", modifier: "temp", argument: "#F04138" },
                        { call: "location", modifier: "temp", argument: "top" },
                        {
                        call: "transition",
                        modifier: "temp",
                        argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                        },
                    ],
                    }, 
            }
        },
        {
            path: '/codigo-conducta',
            name: 'codigo-conducta',
            component: () => import('@/components/EthicalPrinciples.vue'),
            meta: {
                title: 'Código de Conducta',
                progress: {
                    func: [
                        { call: "color", modifier: "temp", argument: "#F58228" },
                        { call: "fail", modifier: "temp", argument: "#F04138" },
                        { call: "location", modifier: "temp", argument: "top" },
                        {
                        call: "transition",
                        modifier: "temp",
                        argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                        },
                    ],
                    }, 
            }
        },
        {
            path: '/soluciones',
            name: 'soluciones',
            component: () =>  import('@/components/SolutionsPage.vue'),
            meta: {
                title: 'Soluciones',
                progress: {
                    func: [
                        { call: "color", modifier: "temp", argument: "#F58228" },
                        { call: "fail", modifier: "temp", argument: "#F04138" },
                        { call: "location", modifier: "temp", argument: "top" },
                        {
                        call: "transition",
                        modifier: "temp",
                        argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                        },
                    ],
                    }, 
            }
        },
        {
            path: '/trabaja-en-tein',
            name: 'trabaja-en-tein',
            component: () =>  import('@/components/JobPage.vue'),
            meta: {
                title: 'Trabaja en Tein',
                progress: {
                    func: [
                        { call: "color", modifier: "temp", argument: "#F58228" },
                        { call: "fail", modifier: "temp", argument: "#F04138" },
                        { call: "location", modifier: "temp", argument: "top" },
                        {
                        call: "transition",
                        modifier: "temp",
                        argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                        },
                    ],
                    }, 
            }
        },
        {
            path: '/contacto/',
            name: 'contacto',
            component: () =>  import('@/components/ContactPage.vue'),
            meta: {
                title: 'Contacto',
                progress: {
                    func: [
                        { call: "color", modifier: "temp", argument: "#F58228" },
                        { call: "fail", modifier: "temp", argument: "#F04138" },
                        { call: "location", modifier: "temp", argument: "top" },
                        {
                        call: "transition",
                        modifier: "temp",
                        argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                        },
                    ],
                    }, 
            }
        },
        {
            path: '/soluciones/servicios-profesionales',
            name: 'servicios-profesionales',
            component: () =>  import('@/components/ProfessionalServices.vue'),
            meta: {
                title: 'Servicios Profesionales',
                progress: {
                    func: [
                        { call: "color", modifier: "temp", argument: "#F58228" },
                        { call: "fail", modifier: "temp", argument: "#F04138" },
                        { call: "location", modifier: "temp", argument: "top" },
                        {
                        call: "transition",
                        modifier: "temp",
                        argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                        },
                    ],
                    }, 
            }
        },
        {
            path: '/soluciones/optimizacion-contact-center',
            name: 'optimizacion-contact-center',
            component: () =>  import('@/components/CallCenterOptimization.vue'),
            meta: {
                title: 'Optimización del Contact Center',
                progress: {
                    func: [
                        { call: "color", modifier: "temp", argument: "#F58228" },
                        { call: "fail", modifier: "temp", argument: "#F04138" },
                        { call: "location", modifier: "temp", argument: "top" },
                        {
                        call: "transition",
                        modifier: "temp",
                        argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                        },
                    ],
                    }, 
            }
        },
        {
            path: '/soluciones/seguridad',
            name: 'seguridad',
            component: () =>  import('@/components/SecurityPage.vue'),
            meta: {
                title: 'Seguridad',
                progress: {
                    func: [
                        { call: "color", modifier: "temp", argument: "#F58228" },
                        { call: "fail", modifier: "temp", argument: "#F04138" },
                        { call: "location", modifier: "temp", argument: "top" },
                        {
                        call: "transition",
                        modifier: "temp",
                        argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                        },
                    ],
                    }, 
            }
        },
        {
            path: '/soluciones/analisis-integracion-datos',
            name: 'analisis-integracion-data',
            component: () =>   import('@/components/AnalyticsIntegration.vue'),
            meta: {
                title: 'Análisis e Integración de Datos',
                progress: {
                    func: [
                        { call: "color", modifier: "temp", argument: "#F58228" },
                        { call: "fail", modifier: "temp", argument: "#F04138" },
                        { call: "location", modifier: "temp", argument: "top" },
                        {
                        call: "transition",
                        modifier: "temp",
                        argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                        },
                    ],
                    }, 
            }
        },
        {
            path: '/legal',
            name: 'legal',
            component: () =>  import('@/components/OurPolicies.vue'),
            meta: {
                title: 'Legal',
                progress: {
                    func: [
                        { call: "color", modifier: "temp", argument: "#F58228" },
                        { call: "fail", modifier: "temp", argument: "#F04138" },
                        { call: "location", modifier: "temp", argument: "top" },
                        {
                        call: "transition",
                        modifier: "temp",
                        argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                        },
                    ],
                    }, 
            }
        },
        // {
        //     path: '/404',
        //     //name: 'not-found',
        //     component: NotFound
        // },
        {
            path: '/:catchAll(.*)*',
            redirect:'/'
        },
    ]
})

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    document.description = `${to.meta.description}`;
    next();
})

export default router
