
<template>
    
    <!-- <SpinnerLoading v-if="isLoading"></SpinnerLoading> -->
    <div class="main antialiased relative">
    <!-- Custom Cursor -->
    <div :class="[ 'g-cursor', { 'g-cursor_hover': hover }, {'g-cursor_hide': hideCursor} ]">
      <div :style="cursorCircle" class="g-cursor__circle"></div>
      <div class="g-cursor__point" ref="point" :style="cursorPoint"></div>
    </div>   
    <!-- End Custom Cursor -->
    <HeaderNavegation></HeaderNavegation>
    <transition name="fade" mode="out-in" v-on:after-enter="afterEnter" appear>   
      <router-view />
    </transition> 
      <vue-progress-bar></vue-progress-bar>

    <FooterModule></FooterModule>
    </div> 
</template>

<script>
import HeaderNavegation from './components/globals/HeaderNavegation.vue'
import FooterModule from './components/globals/footerModule.vue'
// import SpinnerLoading from './components/globals/SpinnerLoading.vue'
//import  NotFound  from './components/globals/NotFound.vue';

export default {
  name: 'App',
  components: {
    HeaderNavegation,
    FooterModule,
    // SpinnerLoading
    //NotFound
  
  },
  data() {
    return {
      isLoading: true,
      // Cursor
      xChild: 0,
      yChild: 0,
      xParent: 0,
      yParent: 0,
      hover: false,
      hideCursor: true
      // End Cursor
    }
  },

  computed: {
    cursorCircle() {
      return `transform: translateX(${this.xParent}px) translateY(${this.yParent}px) translateZ(0) translate3d(0, 0, 0);`
    },
    cursorPoint() {
      return `transform: translateX(${this.xChild - 3}px) translateY(${this.yChild - 3}px) translateZ(0) translate3d(0, 0, 0);`
    }
  },

  mounted() {
      this.$Progress.finish();
      document.addEventListener("mousemove", this.moveCursor);
      document.addEventListener('mouseleave', e => {
        this.hideCursor = true;
      });
      document.addEventListener('mouseenter', e => {
        this.hideCursor = false;
      });
    
  },
  created() {
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
    //  does the page we want to go to have a meta.progress object
    if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
    }
    //  start the progress bar
    this.$Progress.start();
    //  continue to next page
    next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from, next) => {
    //  finish the progress bar
    this.$Progress.finish();
    next();
    });
  },

  methods: {
    moveCursor(e) {
      this.xChild = e.clientX;
      this.yChild = e.clientY;
      setTimeout(() => {
        this.xParent = e.clientX - 15;
        this.yParent = e.clientY - 15;
      }, 100);
    }
  }

}

</script>
