import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueClickAway from 'vue3-click-away'
import VueProgressBar from '@aacassandra/vue3-progressbar'
import { VueReCaptcha } from "vue-recaptcha-v3"
//import { VueRecaptcha } from 'vue-recaptcha'

import './styles.css'

const options = {
        color: "#bffaf3",
        failedColor: "#874b4b",
        thickness: "5px",
        transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 300,
        },
        autoRevert: true,
        location: "left",
        inverse: false,
};

const  app = createApp(App)
        app
        .use(router)
        .use(VueClickAway)
        .use(VueProgressBar, options)
        .use(VueGtag, {
                config: { id: 'UA-81037165-1' }
        })
        .use(VueReCaptcha, { siteKey: "6LetA5kkAAAAAEVi9BxSz3sJK6jVhKYuy96Ijp0c", 
        loaderOptions: {
                size: "invisible",
                // explicitRenderParameters: {
                //         container: 'inline-badge',
                //         badge: 'inline',
                //         render: 'explicit',
                //         loadRecaptchaScript: 'true'
                        
                // },
                },
        })
        .use(VueAxios, axios)

        app.mount('#app')    

        app.config.warnHandler = function (msg, vm, trace) {
                return null
        }