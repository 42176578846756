
<template>
    <div class="header" 
            v-bind:class="[isSticky ? stickyClass : '']">
        <header class="lg:relative" :class="{ bgmenuOpen: showMenu }">
        <div class="container flex justify-between items-center md:px-8  navbar" 
        :class="{ isOpen: showMenu }" 
        
        >
            <!-- Logo -->
            <div class="w-3/12 lg:w-auto brand">
                <a href="/" class="brand-color lg:my-3 hidden">
                    <!-- Color Logo -->
                    <svg version="1.1" id="brandColor" class="brandLogo" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="110px" height="40px"
                        viewBox="0 0 316 126" enable-background="new 0 0 316 126" xml:space="preserve">
                        <g>
                            <path id="squareRed" class="square" opacity="0.91" fill-rule="evenodd" clip-rule="evenodd"
                                fill="#EE2E24" d="M61.22,113.793c0,1.234-1.001,2.236-2.237,2.236
                    H38.407c-1.235,0-2.237-1.002-2.237-2.236V93.217c0-1.234,1.002-2.236,2.237-2.236h20.577c1.235,0,2.237,1.002,2.237,2.236V113.793
                    z" />
                            <g>
                                <g>
                                    <path fill="#004E82"
                                        d="M131.37,109.261h-1.913v-0.69h4.656v0.69h-1.922v5.599h-0.821V109.261z" />
                                    <path fill="#004E82" d="M135.942,108.654c0.411-0.084,0.998-0.131,1.558-0.131c0.868,0,1.428,0.159,1.82,0.514
                            c0.317,0.28,0.494,0.709,0.494,1.194c0,0.83-0.522,1.381-1.185,1.604v0.028c0.485,0.168,0.775,0.616,0.924,1.27
                            c0.206,0.877,0.354,1.483,0.485,1.726h-0.84c-0.103-0.187-0.243-0.718-0.42-1.502c-0.187-0.868-0.522-1.194-1.26-1.223h-0.765
                            v2.725h-0.812V108.654z M136.753,111.52h0.831c0.868,0,1.418-0.477,1.418-1.194c0-0.813-0.588-1.167-1.446-1.176
                            c-0.392,0-0.672,0.037-0.803,0.074V111.52z" />
                                    <path fill="#004E82" d="M143.108,112.882l-0.653,1.978h-0.84l2.137-6.289h0.98l2.146,6.289h-0.868l-0.672-1.978H143.108z
                            M145.17,112.247l-0.616-1.811c-0.14-0.41-0.233-0.783-0.327-1.147h-0.019c-0.094,0.373-0.196,0.756-0.317,1.139l-0.616,1.819
                            H145.17z" />
                                    <path fill="#004E82" d="M148.93,114.859v-6.289h0.886l2.016,3.182c0.467,0.737,0.831,1.4,1.129,2.044l0.019-0.009
                            c-0.075-0.84-0.093-1.605-0.093-2.585v-2.632h0.765v6.289h-0.821l-1.997-3.191c-0.438-0.699-0.858-1.418-1.176-2.099
                            l-0.028,0.009c0.047,0.793,0.065,1.549,0.065,2.594v2.688H148.93z" />
                                    <path fill="#004E82" d="M156.086,113.87c0.364,0.225,0.896,0.411,1.456,0.411c0.83,0,1.315-0.438,1.315-1.073
                            c0-0.588-0.336-0.924-1.185-1.25c-1.026-0.364-1.661-0.896-1.661-1.782c0-0.98,0.812-1.708,2.034-1.708
                            c0.644,0,1.11,0.149,1.39,0.308l-0.224,0.663c-0.205-0.112-0.625-0.299-1.194-0.299c-0.858,0-1.185,0.514-1.185,0.942
                            c0,0.588,0.383,0.877,1.25,1.213c1.064,0.411,1.605,0.924,1.605,1.848c0,0.971-0.719,1.819-2.202,1.819
                            c-0.606,0-1.269-0.187-1.605-0.41L156.086,113.87z" />
                                    <path fill="#004E82"
                                        d="M161.928,108.57h3.387v0.682h-2.576v2.09h2.38v0.672h-2.38v2.846h-0.812V108.57z" />
                                    <path fill="#004E82" d="M172.976,111.649c0,2.165-1.315,3.313-2.921,3.313c-1.661,0-2.827-1.287-2.827-3.19
                            c0-1.997,1.241-3.304,2.921-3.304C171.865,108.468,172.976,109.783,172.976,111.649z M168.096,111.752
                            c0,1.344,0.728,2.548,2.006,2.548c1.287,0,2.016-1.185,2.016-2.612c0-1.251-0.653-2.557-2.007-2.557
                            C168.767,109.131,168.096,110.371,168.096,111.752z" />
                                    <path fill="#004E82" d="M175.158,108.654c0.411-0.084,0.999-0.131,1.559-0.131c0.868,0,1.428,0.159,1.819,0.514
                            c0.317,0.28,0.495,0.709,0.495,1.194c0,0.83-0.522,1.381-1.186,1.604v0.028c0.485,0.168,0.774,0.616,0.924,1.27
                            c0.205,0.877,0.354,1.483,0.485,1.726h-0.84c-0.103-0.187-0.242-0.718-0.42-1.502c-0.187-0.868-0.522-1.194-1.26-1.223h-0.765
                            v2.725h-0.813V108.654z M175.971,111.52h0.83c0.868,0,1.418-0.477,1.418-1.194c0-0.813-0.588-1.167-1.446-1.176
                            c-0.392,0-0.672,0.037-0.802,0.074V111.52z" />
                                    <path fill="#004E82" d="M186.607,112.098c-0.047-0.877-0.103-1.932-0.093-2.716h-0.028c-0.215,0.737-0.476,1.53-0.793,2.39
                            l-1.11,3.051h-0.616l-1.017-2.995c-0.299-0.887-0.551-1.698-0.729-2.445h-0.019c-0.019,0.784-0.065,1.839-0.121,2.781
                            l-0.168,2.696h-0.774l0.438-6.289h1.036l1.072,3.042c0.262,0.774,0.477,1.465,0.635,2.118h0.028
                            c0.158-0.635,0.383-1.325,0.662-2.118l1.12-3.042h1.035l0.393,6.289h-0.793L186.607,112.098z" />
                                    <path fill="#004E82" d="M190.955,112.882l-0.652,1.978h-0.84l2.137-6.289h0.979l2.146,6.289h-0.868l-0.672-1.978H190.955z
                            M193.018,112.247l-0.616-1.811c-0.14-0.41-0.232-0.783-0.326-1.147h-0.019c-0.094,0.373-0.196,0.756-0.317,1.139l-0.616,1.819
                            H193.018z" />
                                    <path fill="#004E82" d="M196.777,114.859v-6.289h0.887l2.016,3.182c0.467,0.737,0.83,1.4,1.129,2.044l0.019-0.009
                            c-0.074-0.84-0.093-1.605-0.093-2.585v-2.632h0.765v6.289h-0.821l-1.996-3.191c-0.438-0.699-0.858-1.418-1.176-2.099
                            l-0.028,0.009c0.047,0.793,0.065,1.549,0.065,2.594v2.688H196.777z" />
                                    <path fill="#004E82"
                                        d="M204.046,108.654c0.495-0.074,1.083-0.131,1.727-0.131c1.167,0,1.997,0.271,2.548,0.784
                            c0.56,0.514,0.886,1.241,0.886,2.258c0,1.026-0.316,1.866-0.904,2.445c-0.588,0.588-1.559,0.905-2.781,0.905
                            c-0.578,0-1.063-0.028-1.475-0.075V108.654z M204.858,114.216c0.205,0.037,0.504,0.047,0.821,0.047
                            c1.735,0,2.678-0.971,2.678-2.669c0.009-1.483-0.83-2.426-2.548-2.426c-0.42,0-0.736,0.037-0.951,0.084V114.216z" />
                                    <path fill="#004E82" d="M216.765,111.649c0,2.165-1.316,3.313-2.921,3.313c-1.661,0-2.827-1.287-2.827-3.19
                            c0-1.997,1.241-3.304,2.92-3.304C215.654,108.468,216.765,109.783,216.765,111.649z M211.884,111.752
                            c0,1.344,0.729,2.548,2.007,2.548c1.287,0,2.016-1.185,2.016-2.612c0-1.251-0.653-2.557-2.007-2.557
                            C212.556,109.131,211.884,110.371,211.884,111.752z" />
                                    <path fill="#004E82" d="M222.055,108.57h0.812v5.608h2.688v0.681h-3.499V108.57z" />
                                    <path fill="#004E82" d="M228.604,112.882l-0.653,1.978h-0.84l2.137-6.289h0.98l2.146,6.289h-0.867l-0.672-1.978H228.604z
                            M230.667,112.247l-0.616-1.811c-0.14-0.41-0.233-0.783-0.326-1.147h-0.02c-0.093,0.373-0.195,0.756-0.316,1.139l-0.616,1.819
                            H230.667z" />
                                    <path fill="#004E82"
                                        d="M240.79,111.911h-2.444v2.268h2.725v0.681h-3.537v-6.289h3.397v0.682h-2.585v1.987h2.444V111.911z" />
                                    <path fill="#004E82" d="M246.696,114.859l-0.803-1.39c-0.326-0.532-0.531-0.878-0.728-1.241h-0.019
                            c-0.177,0.363-0.354,0.699-0.681,1.25l-0.756,1.381h-0.934l1.922-3.182l-1.848-3.107h0.943l0.83,1.475
                            c0.233,0.41,0.41,0.728,0.578,1.063h0.028c0.178-0.373,0.336-0.662,0.569-1.063l0.858-1.475h0.933l-1.912,3.061l1.959,3.229
                            H246.696z" />
                                    <path fill="#004E82"
                                        d="M249.71,108.646c0.392-0.065,0.905-0.122,1.559-0.122c0.803,0,1.391,0.188,1.764,0.523
                            c0.345,0.298,0.551,0.755,0.551,1.315c0,0.569-0.168,1.017-0.485,1.344c-0.43,0.457-1.129,0.69-1.923,0.69
                            c-0.242,0-0.466-0.01-0.653-0.057v2.52h-0.812V108.646z M250.521,111.678c0.178,0.047,0.401,0.065,0.672,0.065
                            c0.98,0,1.577-0.476,1.577-1.344c0-0.83-0.588-1.231-1.483-1.231c-0.354,0-0.625,0.027-0.766,0.065V111.678z" />
                                    <path fill="#004E82"
                                        d="M259.06,111.911h-2.444v2.268h2.725v0.681h-3.537v-6.289h3.396v0.682h-2.584v1.987h2.444V111.911z" />
                                    <path fill="#004E82" d="M261.522,108.654c0.411-0.084,0.999-0.131,1.559-0.131c0.868,0,1.428,0.159,1.819,0.514
                            c0.317,0.28,0.495,0.709,0.495,1.194c0,0.83-0.522,1.381-1.186,1.604v0.028c0.485,0.168,0.774,0.616,0.924,1.27
                            c0.205,0.877,0.354,1.483,0.485,1.726h-0.84c-0.103-0.187-0.242-0.718-0.42-1.502c-0.187-0.868-0.522-1.194-1.26-1.223h-0.765
                            v2.725h-0.813V108.654z M262.335,111.52h0.83c0.868,0,1.418-0.477,1.418-1.194c0-0.813-0.588-1.167-1.446-1.176
                            c-0.392,0-0.672,0.037-0.802,0.074V111.52z" />
                                    <path fill="#004E82" d="M268.483,108.57v6.289h-0.812v-6.289H268.483z" />
                                    <path fill="#004E82"
                                        d="M274.287,111.911h-2.444v2.268h2.725v0.681h-3.537v-6.289h3.396v0.682h-2.584v1.987h2.444V111.911z" />
                                    <path fill="#004E82" d="M276.75,114.859v-6.289h0.887l2.016,3.182c0.467,0.737,0.83,1.4,1.129,2.044l0.019-0.009
                            c-0.074-0.84-0.093-1.605-0.093-2.585v-2.632h0.765v6.289h-0.821l-1.997-3.191c-0.438-0.699-0.858-1.418-1.176-2.099
                            l-0.027,0.009c0.047,0.793,0.065,1.549,0.065,2.594v2.688H276.75z" />
                                    <path fill="#004E82"
                                        d="M288.414,114.654c-0.299,0.149-0.896,0.308-1.661,0.308c-1.773,0-3.107-1.129-3.107-3.19
                            c0-1.97,1.334-3.304,3.284-3.304c0.784,0,1.278,0.168,1.493,0.28l-0.196,0.662c-0.308-0.149-0.746-0.261-1.269-0.261
                            c-1.475,0-2.454,0.942-2.454,2.594c0,1.54,0.887,2.528,2.417,2.528c0.494,0,0.998-0.103,1.325-0.261L288.414,114.654z" />
                                    <path fill="#004E82" d="M291.371,108.57v6.289h-0.812v-6.289H291.371z" />
                                    <path fill="#004E82" d="M294.936,112.882l-0.653,1.978h-0.84l2.137-6.289h0.979l2.146,6.289h-0.867l-0.672-1.978H294.936z
                            M296.998,112.247l-0.616-1.811c-0.14-0.41-0.233-0.783-0.327-1.147h-0.019c-0.093,0.373-0.195,0.756-0.317,1.139l-0.615,1.819
                            H296.998z" />
                                </g>
                            </g>
                            <path fill="#F58228" d="M140.742,41.526h11.957v10.922h-11.957v29.434c0,5.519,1.379,6.898,6.898,6.898
                    c1.725,0,3.334-0.114,5.059-0.46v12.762c-2.759,0.461-6.323,0.576-9.542,0.576c-10.003,0-18.741-2.301-18.741-14.143V52.448h-9.888
                    V41.526h9.888V23.705h16.327V41.526z" />
                            <path fill="#F58228" d="M169.068,75.213c0.461,10.348,5.52,15.063,14.603,15.063c6.554,0,11.843-4.024,12.877-7.703h14.372
                    c-4.6,14.026-14.372,20.006-27.824,20.006c-18.741,0-30.353-12.878-30.353-31.273c0-17.822,12.302-31.389,30.353-31.389
                    c20.236,0,30.009,17.016,28.859,35.297H169.068z M195.628,64.865c-1.495-8.277-5.059-12.647-12.992-12.647
                    c-10.348,0-13.337,8.048-13.567,12.647H195.628z" />
                            <g>
                                <path fill="#9BBE3C" d="M217.831,98.459c0,1.409,1.143,2.552,2.551,2.552h11.906c1.408,0,2.551-1.143,2.551-2.552V44.034
                        c0-1.409-1.143-2.551-2.551-2.551h-11.906c-1.408,0-2.551,1.142-2.551,2.551V98.459z" />
                                <path fill="none" stroke="#9BBE3C" stroke-width="2"
                                    d="M217.831,98.459c0,1.409,1.143,2.552,2.551,2.552h11.906
                        c1.408,0,2.551-1.143,2.551-2.552V44.034c0-1.409-1.143-2.551-2.551-2.551h-11.906c-1.408,0-2.551,1.142-2.551,2.551V98.459z" />
                            </g>
                            <path fill="#9BBE3C" stroke="#9BBE3C" stroke-width="2" d="M242.477,41.526h15.521v8.278h0.345
                    c4.14-6.668,11.268-9.888,18.166-9.888c17.361,0,21.73,9.773,21.73,24.49v36.562h-16.327V67.395
                    c0-9.772-2.874-14.601-10.463-14.601c-8.853,0-12.646,4.944-12.646,17.016v31.158h-16.326V41.526z" />
                            <g>
                                <path fill="#004E82" d="M306.217,22.932c-0.557-1.006-1.342-1.78-2.344-2.318c-1-0.54-2.032-0.81-3.08-0.81
                        c-1.056,0-2.087,0.27-3.086,0.81c-1.003,0.539-1.787,1.312-2.352,2.318c-0.557,1.008-0.839,2.057-0.839,3.147
                        c0,1.08,0.275,2.116,0.833,3.118c0.551,0.994,1.324,1.768,2.32,2.318c0.994,0.553,2.031,0.829,3.123,0.829
                        c1.084,0,2.121-0.276,3.117-0.829c0.994-0.551,1.769-1.324,2.318-2.318c0.553-1.002,0.83-2.038,0.83-3.118
                        C307.059,24.989,306.775,23.94,306.217,22.932z M305.324,28.676c-0.455,0.827-1.104,1.474-1.932,1.935
                        c-0.832,0.462-1.701,0.696-2.6,0.696c-0.904,0-1.775-0.234-2.602-0.696c-0.834-0.46-1.481-1.108-1.941-1.935
                        c-0.463-0.829-0.695-1.698-0.695-2.597c0-0.911,0.232-1.787,0.707-2.625c0.467-0.839,1.12-1.481,1.959-1.937
                        c0.832-0.449,1.686-0.677,2.572-0.677c0.875,0,1.725,0.228,2.564,0.677c0.84,0.456,1.486,1.097,1.961,1.937
                        c0.468,0.838,0.695,1.713,0.695,2.625C306.014,26.978,305.786,27.847,305.324,28.676z" />
                                <path fill="#004E82" d="M301.883,26.883c-0.137-0.137-0.328-0.258-0.568-0.354c0.58-0.047,1.049-0.251,1.402-0.617
                        c0.348-0.367,0.527-0.797,0.527-1.289c0-0.348-0.107-0.677-0.316-0.989c-0.211-0.312-0.492-0.527-0.846-0.653
                        c-0.359-0.127-0.93-0.186-1.727-0.186h-2.313v6.75h1.085v-2.866h0.64c0.385,0,0.671,0.078,0.877,0.234
                        c0.286,0.21,0.665,0.731,1.132,1.564l0.6,1.067h1.331l-0.821-1.32C302.488,27.596,302.16,27.152,301.883,26.883z M300.383,25.528
                        h-1.323v-2.032h1.239c0.534,0,0.896,0.036,1.094,0.114c0.195,0.078,0.352,0.197,0.459,0.353c0.109,0.156,0.17,0.335,0.17,0.534
                        c0,0.313-0.115,0.564-0.35,0.75C301.445,25.433,301.014,25.528,300.383,25.528z" />
                            </g>
                            <g>
                                <path fill="#9BBE3C" d="M217.831,30.609c0,1.409,1.143,2.551,2.551,2.551h11.906c1.408,0,2.551-1.142,2.551-2.551v-9.071
                        c0-1.409-1.143-2.551-2.551-2.551h-11.906c-1.408,0-2.551,1.142-2.551,2.551V30.609z" />
                            </g>
                            <path fill-rule="evenodd" clip-rule="evenodd" fill="#E0E31C" class="square" id="squareYellow"
                                d="M59.689,36.69c0,1.595-1.294,2.889-2.89,2.889H30.222
                    c-1.596,0-2.889-1.294-2.889-2.889V10.113c0-1.596,1.293-2.89,2.889-2.89h26.577c1.596,0,2.89,1.294,2.89,2.89V36.69z" />
                            <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#FFFFFF" stroke-width="4"
                                stroke-miterlimit="10" d="
                    M81.182,58.104c0,1.406-1.141,2.549-2.547,2.549H55.206c-1.406,0-2.547-1.143-2.547-2.549V34.676c0-1.406,1.141-2.546,2.547-2.546
                    h23.429c1.406,0,2.547,1.14,2.547,2.546V58.104z" />
                            <path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#FFFFFF" stroke-width="6"
                                stroke-miterlimit="10" d="
                    M80.543,95.219c0,1.283-1.042,2.326-2.325,2.326H56.834c-1.284,0-2.325-1.043-2.325-2.326V73.834c0-1.281,1.041-2.322,2.325-2.322
                    h21.383c1.284,0,2.325,1.041,2.325,2.322V95.219z" />
                            <path fill-rule="evenodd" clip-rule="evenodd" fill="#004E82" id="squareBlue" class="square"
                                d="M81.283,51.377l0,0.775l-6.072,2.479l-2.79,6.145H55.308
                    c-1.407,0-2.547-1.139-2.547-2.545V34.802c0-1.407,1.14-2.547,2.547-2.547h23.429c1.406,0,2.547,1.14,2.547,2.547V51.377z" />
                            <path fill-rule="evenodd" clip-rule="evenodd" fill="#9BBE3C" id="squareGreen" class="square"
                                d="M47.907,80.658c0,1.748-1.418,3.168-3.168,3.168H15.591
                    c-1.75,0-3.168-1.42-3.168-3.168V51.511c0-1.749,1.419-3.168,3.168-3.168h29.147c1.75,0,3.168,1.419,3.168,3.168V80.658z" />
                            <path fill-rule="evenodd" class="square" clip-rule="evenodd" fill="#FFFFFF"
                                d="M79.649,76.34V58.654v36.633c0,1.283-1.042,2.324-2.325,2.324
                    H55.94c-1.284,0-2.324-1.041-2.324-2.324V73.902c0-1.283,1.041-2.324,2.324-2.324h21.384c1.284,0,2.325,1.041,2.325,2.324V76.34" />
                            <path fill-rule="evenodd" id="squareOrangeLight" class="square" clip-rule="evenodd" fill="none"
                                stroke="#FFFFFF" stroke-width="3" stroke-miterlimit="10" d="
                    M99.547,77.52c0,1.314-1.063,2.379-2.376,2.379h-21.86c-1.312,0-2.376-1.064-2.376-2.379V55.662c0-1.312,1.064-2.377,2.376-2.377
                    h21.86c1.313,0,2.376,1.065,2.376,2.377V77.52z" />
                            <path fill-rule="evenodd" id="squareOrange" class="square" clip-rule="evenodd" fill="none"
                                stroke="#F58127" stroke-width="3.5" stroke-miterlimit="10" d="
                    M80.476,94.988c0,1.283-1.041,2.324-2.325,2.324H56.768c-1.284,0-2.325-1.041-2.325-2.324V73.604c0-1.283,1.042-2.324,2.325-2.324
                    h21.384c1.284,0,2.325,1.041,2.325,2.324V94.988z" />
                            <path id="squareOrangeLine" class="square" fill-rule="evenodd" clip-rule="evenodd" fill="none"
                                stroke="#FCB527" stroke-width="2.5" stroke-miterlimit="10" d="
                    M73.772,69.125V56.08c0-1.19,0.966-2.156,2.157-2.156h19.844c1.191,0,2.156,0.966,2.156,2.156v19.846
                    c0,1.189-0.965,2.154-2.156,2.154H75.929c-1.191,0-2.157-0.965-2.157-2.154v-2.504" />
                            <path fill="#9BBE3C"
                                d="M79.83,55.983v2.52c0,0.878-0.713,1.592-1.592,1.592h-2.249v-4.111h3.014H79.83z" />
                            <path fill="#9BBE3C"
                                d="M57.731,91.626h1.763c0.615,0,1.114,0.498,1.114,1.113v1.573h-2.877v-2.107V91.626z" />
                            <rect x="-27.5" y="-19" fill-rule="evenodd" clip-rule="evenodd" fill="none" width="372.5"
                                height="164.333" />
                        </g>
                    </svg>
                </a>
                <!-- White Logo -->
                <a href="#" class="brand-white block lg:my-3">
                    <svg version="1.1" id="brandWhite" class="brandLogo" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="110px" height="40px"
                    viewBox="0 0 150 54" enable-background="new 0 0 150 54" xml:space="preserve">
                    <g>
                        <path id="sq-bottom" class="square"  fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF"
                            d="M26.198,52.762c0,0.609-0.496,1.104-1.108,1.104
                        H14.897c-0.611,0-1.108-0.494-1.108-1.104V42.6c0-0.609,0.497-1.102,1.108-1.102h10.192c0.612,0,1.108,0.492,1.108,1.102V52.762z" />
                        <g>
                            <path id="sq-top" class="square" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M35.094,25.26c0,0.15-0.121,0.271-0.27,0.271H23.219
                            c-0.15,0-0.271-0.121-0.271-0.271V13.691c0-0.148,0.121-0.27,0.271-0.27h11.605c0.148,0,0.27,0.121,0.27,0.27V25.26z
                            M37.077,13.691c0-1.238-1.012-2.244-2.252-2.244H23.219c-1.243,0-2.254,1.006-2.254,2.244V25.26c0,1.24,1.011,2.246,2.254,2.246
                            h11.605c1.24,0,2.252-1.006,2.252-2.246V13.691z" />
                            <path id="sq-tt" class="square" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF"
                            d="M25.439,14.688c0,0.787-0.641,1.426-1.431,1.426H10.843
                        c-0.791,0-1.432-0.639-1.432-1.426V1.563c0-0.789,0.641-1.428,1.432-1.428h13.165c0.79,0,1.431,0.639,1.431,1.428V14.688z" />
                        </g>
                        <g id="sq-rb" class="square">
                            <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M34.284,43.59c0-0.184,0.148-0.334,0.334-0.334H24.025
                            c0.185,0,0.335,0.15,0.335,0.334V33.029c0,0.088-0.036,0.176-0.099,0.236c-0.062,0.063-0.147,0.098-0.236,0.098h10.593
                            c-0.186,0-0.334-0.148-0.334-0.334V43.59z M37.254,33.029c0-1.449-1.182-2.629-2.637-2.629H24.025
                            c-1.453,0-2.637,1.18-2.637,2.629V43.59c0,0.701,0.273,1.363,0.771,1.857c0.499,0.498,1.162,0.771,1.866,0.771h10.593
                            c1.455,0,2.637-1.18,2.637-2.629V33.029z" />
                        </g>
                        <path id="sq-rt" class="square" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF"
                            d="M36.135,21.939v0.383l-3.008,1.223l-1.381,3.035h-8.478
                        c-0.697,0-1.262-0.563-1.262-1.256v-11.57c0-0.695,0.564-1.258,1.262-1.258h11.604c0.697,0,1.262,0.563,1.262,1.258V21.939z" />
                        <path id="sq-c" class="square" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF"
                            d="M19.603,36.398c0,0.863-0.703,1.564-1.569,1.564H3.595
                        c-0.867,0-1.57-0.701-1.57-1.564V22.006c0-0.863,0.703-1.564,1.57-1.564h14.438c0.866,0,1.569,0.701,1.569,1.564V36.398z" />
                        <g id="sq-line-t" class="square">
                            <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M44.44,34.85c0,0.238-0.195,0.434-0.434,0.434H33.178
                            c-0.24,0-0.436-0.195-0.436-0.434V24.055c0-0.238,0.195-0.434,0.436-0.434h10.828c0.238,0,0.434,0.195,0.434,0.434V34.85z
                            M45.926,24.055c0-1.055-0.861-1.914-1.92-1.914H33.178c-1.059,0-1.922,0.859-1.922,1.914V34.85c0,1.055,0.863,1.914,1.922,1.914
                            h10.828c1.059,0,1.92-0.859,1.92-1.914V24.055z" />
                            <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M33.034,30.703v-6.441c0-0.246,0.201-0.447,0.449-0.447h9.83
                            c0.248,0,0.449,0.201,0.449,0.447v9.801c0,0.119-0.047,0.23-0.131,0.314c-0.086,0.086-0.199,0.131-0.318,0.131h-9.83
                            c-0.119,0-0.232-0.045-0.318-0.131c-0.084-0.084-0.131-0.195-0.131-0.314v-1.236h-1.238v1.236c0,0.447,0.176,0.871,0.494,1.189
                            c0.32,0.316,0.742,0.492,1.193,0.492h9.83c0.451,0,0.875-0.176,1.193-0.492C44.825,34.934,45,34.51,45,34.063v-9.801
                            c0-0.928-0.756-1.682-1.688-1.682h-9.83c-0.93,0-1.688,0.754-1.688,1.682v6.441H33.034z" />
                        </g>
                        <g id="sq-line-b" class="square">
                            <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M34.868,43.475c0,0.158-0.127,0.285-0.283,0.285H23.993
                            c-0.157,0-0.285-0.127-0.285-0.285V32.916c0-0.156,0.128-0.285,0.285-0.285h10.592c0.156,0,0.283,0.129,0.283,0.285V43.475z
                            M36.604,32.916c0-1.109-0.906-2.014-2.02-2.014H23.993c-1.113,0-2.019,0.904-2.019,2.014v10.559c0,1.109,0.905,2.014,2.019,2.014
                            h10.592c1.113,0,2.02-0.904,2.02-2.014V32.916z" />
                            <path fill="#FFFFFF"
                            d="M35.415,24.213v1.244c0,0.434-0.352,0.787-0.787,0.787h-1.115v-2.031h1.494H35.415z" />
                            <path fill="#FFFFFF"
                            d="M24.469,41.816h0.874c0.304,0,0.551,0.244,0.551,0.549v0.777h-1.425v-1.041V41.816z" />
                        </g>
    
                        <g id="brand">
                            <g id="slogan" class="opacity-1">
                                <g>
                                    <path fill="#FFFFFF"
                                        d="M60.948,50.523h-0.949v-0.342h2.308v0.342h-0.952v2.766h-0.406V50.523z" />
                                    <path fill="#FFFFFF" d="M63.212,50.225c0.203-0.043,0.494-0.066,0.771-0.066c0.43,0,0.707,0.08,0.9,0.254
                                    c0.158,0.141,0.244,0.352,0.244,0.592c0,0.408-0.258,0.682-0.586,0.791v0.016c0.24,0.082,0.385,0.303,0.457,0.625
                                    c0.102,0.434,0.176,0.734,0.24,0.854h-0.416c-0.051-0.092-0.119-0.355-0.207-0.742c-0.092-0.43-0.258-0.59-0.623-0.604h-0.381
                                    v1.346h-0.4V50.225z M63.613,51.639h0.412c0.43,0,0.703-0.234,0.703-0.59c0-0.402-0.291-0.576-0.717-0.58
                                    c-0.195,0-0.332,0.02-0.398,0.037V51.639z" />
                                    <path fill="#FFFFFF" d="M66.761,52.311l-0.324,0.979h-0.416l1.059-3.107h0.486l1.063,3.107h-0.43l-0.332-0.979H66.761z
                                    M67.783,51.998l-0.305-0.893c-0.07-0.203-0.115-0.389-0.162-0.568h-0.01c-0.045,0.186-0.096,0.375-0.156,0.563l-0.305,0.898
                                    H67.783z" />
                                    <path fill="#FFFFFF" d="M69.646,53.289v-3.107h0.438l1,1.572c0.23,0.363,0.41,0.691,0.559,1.01l0.01-0.006
                                    c-0.037-0.414-0.047-0.791-0.047-1.275v-1.301h0.379v3.107h-0.406l-0.988-1.576c-0.219-0.346-0.426-0.701-0.584-1.037
                                    l-0.014,0.006c0.023,0.391,0.033,0.764,0.033,1.279v1.328H69.646z" />
                                    <path fill="#FFFFFF" d="M73.191,52.799c0.18,0.111,0.443,0.203,0.721,0.203c0.412,0,0.652-0.215,0.652-0.529
                                    c0-0.289-0.168-0.455-0.588-0.617c-0.508-0.18-0.822-0.443-0.822-0.879c0-0.486,0.402-0.846,1.008-0.846
                                    c0.318,0,0.551,0.074,0.688,0.152l-0.111,0.328c-0.102-0.057-0.309-0.146-0.592-0.146c-0.424,0-0.586,0.252-0.586,0.465
                                    c0,0.289,0.189,0.432,0.619,0.598c0.527,0.203,0.795,0.457,0.795,0.914c0,0.479-0.355,0.898-1.092,0.898
                                    c-0.299,0-0.627-0.094-0.793-0.203L73.191,52.799z" />
                                    <path fill="#FFFFFF"
                                        d="M76.084,50.182h1.678v0.338h-1.275v1.031h1.18v0.332h-1.18v1.406h-0.402V50.182z" />
                                    <path fill="#FFFFFF"
                                    d="M81.557,51.703c0,1.068-0.65,1.637-1.445,1.637c-0.824,0-1.402-0.637-1.402-1.576
                                    c0-0.986,0.615-1.633,1.447-1.633C81.008,50.131,81.557,50.781,81.557,51.703z M79.139,51.754c0,0.664,0.361,1.258,0.994,1.258
                                    c0.639,0,0.998-0.584,0.998-1.289c0-0.617-0.322-1.264-0.992-1.264C79.473,50.459,79.139,51.072,79.139,51.754z" />
                                    <path fill="#FFFFFF" d="M82.639,50.225c0.203-0.043,0.494-0.066,0.771-0.066c0.43,0,0.707,0.08,0.902,0.254
                                    c0.156,0.141,0.244,0.352,0.244,0.592c0,0.408-0.258,0.682-0.588,0.791v0.016c0.242,0.082,0.385,0.303,0.459,0.625
                                    c0.102,0.434,0.174,0.734,0.24,0.854h-0.416c-0.051-0.092-0.119-0.355-0.209-0.742c-0.092-0.43-0.26-0.59-0.623-0.604h-0.379
                                    v1.346h-0.402V50.225z M83.041,51.639h0.41c0.43,0,0.703-0.234,0.703-0.59c0-0.402-0.291-0.576-0.717-0.58
                                    c-0.193,0-0.332,0.02-0.396,0.037V51.639z" />
                                    <path fill="#FFFFFF"
                                    d="M88.311,51.924c-0.023-0.432-0.051-0.953-0.047-1.342H88.25c-0.107,0.365-0.236,0.758-0.393,1.182
                                    l-0.551,1.506h-0.305l-0.504-1.479c-0.148-0.438-0.273-0.838-0.361-1.209h-0.01c-0.008,0.389-0.031,0.91-0.059,1.375
                                    l-0.084,1.332h-0.383l0.217-3.107h0.514l0.531,1.504c0.129,0.383,0.236,0.723,0.314,1.045h0.014
                                    c0.078-0.313,0.189-0.654,0.328-1.045l0.555-1.504h0.512l0.195,3.107h-0.393L88.311,51.924z" />
                                    <path fill="#FFFFFF" d="M90.463,52.311l-0.322,0.979h-0.416l1.059-3.107h0.484l1.064,3.107H91.9l-0.332-0.979H90.463z
                                    M91.484,51.998l-0.305-0.893c-0.07-0.203-0.115-0.389-0.162-0.568H91.01c-0.047,0.186-0.098,0.375-0.158,0.563l-0.305,0.898
                                    H91.484z" />
                                    <path fill="#FFFFFF" d="M93.348,53.289v-3.107h0.439l0.998,1.572c0.23,0.363,0.412,0.691,0.559,1.01l0.01-0.006
                                    c-0.037-0.414-0.047-0.791-0.047-1.275v-1.301h0.381v3.107h-0.408l-0.988-1.576c-0.217-0.346-0.426-0.701-0.584-1.037
                                    l-0.012,0.006c0.021,0.391,0.031,0.764,0.031,1.279v1.328H93.348z" />
                                    <path fill="#FFFFFF" d="M96.947,50.225c0.246-0.037,0.537-0.066,0.855-0.066c0.58,0,0.99,0.137,1.262,0.389
                                    c0.277,0.254,0.439,0.613,0.439,1.115c0,0.506-0.156,0.922-0.447,1.207c-0.291,0.291-0.773,0.447-1.379,0.447
                                    c-0.285,0-0.525-0.014-0.73-0.037V50.225z M97.35,52.971c0.104,0.018,0.25,0.023,0.408,0.023c0.859,0,1.326-0.48,1.326-1.318
                                    c0.004-0.732-0.412-1.199-1.262-1.199c-0.209,0-0.365,0.02-0.473,0.043V52.971z" />
                                    <path fill="#FFFFFF" d="M103.248,51.703c0,1.068-0.652,1.637-1.447,1.637c-0.822,0-1.4-0.637-1.4-1.576
                                    c0-0.986,0.615-1.633,1.447-1.633C102.699,50.131,103.248,50.781,103.248,51.703z M100.83,51.754
                                    c0,0.664,0.361,1.258,0.994,1.258c0.639,0,0.998-0.584,0.998-1.289c0-0.617-0.324-1.264-0.992-1.264
                                    S100.83,51.072,100.83,51.754z" />
                                    <path fill="#FFFFFF" d="M105.869,50.182h0.4v2.771h1.332v0.336h-1.732V50.182z" />
                                    <path fill="#FFFFFF" d="M109.113,52.311l-0.322,0.979h-0.418l1.059-3.107h0.486l1.063,3.107h-0.43l-0.332-0.979H109.113z
                                    M110.135,51.998l-0.305-0.893c-0.068-0.203-0.115-0.389-0.162-0.568h-0.01c-0.045,0.186-0.096,0.375-0.156,0.563l-0.305,0.898
                                    H110.135z" />
                                    <path fill="#FFFFFF"
                                    d="M115.148,51.832h-1.209v1.121h1.348v0.336h-1.752v-3.107h1.684v0.338h-1.279v0.98h1.209V51.832z" />
                                    <path fill="#FFFFFF" d="M118.074,53.289l-0.396-0.688c-0.162-0.262-0.264-0.434-0.361-0.613h-0.01
                                    c-0.088,0.18-0.176,0.346-0.338,0.619l-0.373,0.682h-0.463l0.951-1.572l-0.914-1.535h0.467l0.412,0.73
                                    c0.115,0.201,0.201,0.357,0.285,0.523h0.016c0.088-0.184,0.166-0.326,0.281-0.523l0.426-0.73h0.461l-0.947,1.514l0.971,1.594
                                    H118.074z" />
                                    <path fill="#FFFFFF"
                                    d="M119.568,50.219c0.193-0.031,0.447-0.061,0.771-0.061c0.396,0,0.688,0.094,0.873,0.26
                                    c0.17,0.148,0.273,0.373,0.273,0.65c0,0.281-0.084,0.502-0.242,0.662c-0.211,0.227-0.559,0.342-0.951,0.342
                                    c-0.121,0-0.23-0.004-0.324-0.027v1.244h-0.4V50.219z M119.969,51.717c0.088,0.023,0.199,0.033,0.334,0.033
                                    c0.484,0,0.781-0.234,0.781-0.664c0-0.41-0.291-0.609-0.736-0.609c-0.176,0-0.309,0.014-0.379,0.035V51.717z" />
                                    <path fill="#FFFFFF"
                                    d="M124.199,51.832h-1.211v1.121h1.35v0.336h-1.752v-3.107h1.682v0.338h-1.279v0.98h1.211V51.832z" />
                                    <path fill="#FFFFFF" d="M125.418,50.225c0.205-0.043,0.496-0.066,0.773-0.066c0.43,0,0.707,0.08,0.9,0.254
                                    c0.156,0.141,0.246,0.352,0.246,0.592c0,0.408-0.26,0.682-0.588,0.791v0.016c0.24,0.082,0.383,0.303,0.457,0.625
                                    c0.102,0.434,0.176,0.734,0.24,0.854h-0.416c-0.051-0.092-0.119-0.355-0.207-0.742c-0.092-0.43-0.258-0.59-0.625-0.604h-0.377
                                    v1.346h-0.404V50.225z M125.822,51.639h0.41c0.43,0,0.701-0.234,0.701-0.59c0-0.402-0.289-0.576-0.715-0.58
                                    c-0.195,0-0.334,0.02-0.396,0.037V51.639z" />
                                    <path fill="#FFFFFF" d="M128.867,50.182v3.107h-0.402v-3.107H128.867z" />
                                    <path fill="#FFFFFF"
                                        d="M131.742,51.832h-1.211v1.121h1.35v0.336h-1.752v-3.107h1.682v0.338h-1.279v0.98h1.211V51.832z" />
                                    <path fill="#FFFFFF" d="M132.963,53.289v-3.107h0.438l0.998,1.572c0.232,0.363,0.412,0.691,0.561,1.01l0.008-0.006
                                    c-0.035-0.414-0.045-0.791-0.045-1.275v-1.301h0.379v3.107h-0.406l-0.99-1.576c-0.217-0.346-0.424-0.701-0.582-1.037
                                    l-0.014,0.006c0.021,0.391,0.033,0.764,0.033,1.279v1.328H132.963z" />
                                    <path fill="#FFFFFF"
                                    d="M138.74,53.188c-0.148,0.072-0.445,0.152-0.822,0.152c-0.881,0-1.541-0.559-1.541-1.576
                                    c0-0.973,0.66-1.633,1.627-1.633c0.389,0,0.633,0.084,0.74,0.139l-0.098,0.326c-0.152-0.072-0.369-0.127-0.629-0.127
                                    c-0.73,0-1.215,0.465-1.215,1.281c0,0.76,0.439,1.248,1.197,1.248c0.244,0,0.494-0.051,0.656-0.129L138.74,53.188z" />
                                    <path fill="#FFFFFF" d="M140.205,50.182v3.107h-0.402v-3.107H140.205z" />
                                    <path fill="#FFFFFF" d="M141.971,52.311l-0.324,0.979h-0.416l1.059-3.107h0.484l1.064,3.107h-0.43l-0.334-0.979H141.971z
                                    M142.992,51.998l-0.307-0.893c-0.068-0.203-0.115-0.389-0.16-0.568h-0.01c-0.047,0.186-0.098,0.375-0.158,0.563l-0.305,0.898
                                    H142.992z" />
                                </g>
                            </g>
                            <path id="brand-t" class="opacity-1 letter" fill="#FFFFFF" d="M65.589,17.074h5.922v5.395h-5.922v14.535c0,2.725,0.684,3.406,3.418,3.406
                            c0.854,0,1.65-0.057,2.504-0.229v6.305c-1.365,0.227-3.131,0.283-4.727,0.283c-4.954,0-9.282-1.137-9.282-6.984V22.469h-4.898
                            v-5.395h4.898V8.273h8.087V17.074z" />
                            <path id="brand-e" class="opacity-1 letter" fill="#FFFFFF" d="M79.621,33.711c0.229,5.109,2.734,7.438,7.234,7.438c3.246,0,5.865-1.988,6.379-3.805h7.119
                            c-2.279,6.928-7.119,9.881-13.783,9.881c-9.283,0-15.036-6.361-15.036-15.445c0-8.801,6.094-15.5,15.036-15.5
                            c10.023,0,14.865,8.402,14.295,17.432H79.621z M92.777,28.6c-0.74-4.088-2.506-6.244-6.436-6.244
                            c-5.125,0-6.605,3.973-6.721,6.244H92.777z" />
                            <path id="brand-i" class="opacity-1 letter" fill="#FFFFFF" d="M110.938,16.559h-5.896c-0.971,0-1.76,0.787-1.76,1.754v26.877c0,0.967,0.789,1.754,1.76,1.754h5.896
                                c0.971,0,1.76-0.787,1.76-1.754V18.313C112.697,17.346,111.908,16.559,110.938,16.559z" />
                            <path id="brand-point" class="opacity-1 letter" fill="#FFFFFF" d="M104.813,13.213h6.352c0.752,0,1.361-0.609,1.361-1.357V7.031c0-0.748-0.609-1.355-1.361-1.355h-6.352
                                c-0.75,0-1.359,0.607-1.359,1.355v4.824C103.453,12.604,104.063,13.213,104.813,13.213z" />
                            <path id="brand-n" class="opacity-1 letter" fill="#FFFFFF" d="M132.842,15.785c-3.455,0-6.611,1.504-8.674,4.07v-2.781V16.58h-0.496h-7.688h-0.496v0.494v29.354v0.494
                            h0.496h8.086h0.496v-0.494V31.041c0-5.617,1.672-7.908,5.77-7.908c3.33,0,4.688,1.947,4.688,6.717v16.578v0.494h0.496h8.088h0.494
                            v-0.494V28.373C144.102,22.148,142.764,15.785,132.842,15.785z" />
                            <g id="copyright" class="opacity-1" >
                                <path fill="#FFFFFF" d="M147.559,7.891c-0.275-0.496-0.664-0.879-1.162-1.145c-0.494-0.266-1.006-0.398-1.523-0.398
                                c-0.525,0-1.035,0.133-1.529,0.398c-0.498,0.266-0.887,0.648-1.166,1.145c-0.275,0.5-0.414,1.018-0.414,1.555
                                c0,0.533,0.135,1.045,0.412,1.541c0.271,0.49,0.654,0.871,1.148,1.145c0.492,0.273,1.008,0.41,1.549,0.41
                                c0.535,0,1.051-0.137,1.543-0.41s0.877-0.654,1.148-1.145c0.273-0.496,0.41-1.008,0.41-1.541
                                C147.975,8.908,147.836,8.391,147.559,7.891z M147.117,10.729c-0.227,0.408-0.547,0.729-0.959,0.955
                                c-0.41,0.229-0.842,0.344-1.285,0.344c-0.449,0-0.881-0.115-1.291-0.344c-0.412-0.227-0.732-0.547-0.961-0.955
                                s-0.344-0.838-0.344-1.283c0-0.449,0.115-0.881,0.35-1.295c0.232-0.414,0.555-0.73,0.971-0.957
                                c0.412-0.223,0.834-0.334,1.275-0.334c0.432,0,0.854,0.111,1.27,0.334c0.416,0.227,0.736,0.543,0.971,0.957
                                c0.232,0.414,0.344,0.846,0.344,1.295C147.457,9.891,147.346,10.32,147.117,10.729z" />
                                <path fill="#FFFFFF" d="M145.41,9.844c-0.066-0.068-0.16-0.127-0.279-0.176c0.287-0.021,0.52-0.123,0.693-0.305
                                c0.172-0.182,0.262-0.393,0.262-0.637c0-0.172-0.053-0.334-0.156-0.488c-0.105-0.154-0.244-0.26-0.42-0.322
                                c-0.178-0.063-0.461-0.092-0.855-0.092h-1.145v3.332h0.537V9.742h0.316c0.191,0,0.332,0.039,0.434,0.115
                                c0.143,0.104,0.33,0.361,0.561,0.773l0.297,0.525h0.66l-0.406-0.65C145.711,10.195,145.549,9.975,145.41,9.844z M144.668,9.174
                                h-0.656V8.172h0.615c0.264,0,0.443,0.016,0.541,0.055s0.174,0.098,0.229,0.174c0.053,0.078,0.084,0.166,0.084,0.264
                                c0,0.154-0.059,0.279-0.174,0.371C145.195,9.127,144.98,9.174,144.668,9.174z" />
                            </g>
                        </g>
                    </g>
                </svg>
                </a>
            </div>
            <transition
                    enter-active-class="ease-out duration-200"
                    enter-class="opacity-0 translate-y-4 translate-y-0"
                    enter-to-class="opacity-100 translate-y-0 scale-100"
                    leave-active-class="ease-in duration-200"
                    leave-class="opacity-100 translate-y-0 scale-100"
                    leave-to-class="opacity-0 translate-y-4 translate-y-0"
                >
            <button class="lg:hidden btn-toggle" @click="toggleNav">
                <span v-if="showMenu == false"  class="ico-hamburger material-icons-outlined !text-5xl">menu</span>
                <span v-if="showMenu == true" class="material-icons-outlined !text-5xl">close</span>
            </button>

            </transition>
            <!-- Main Nav -->
            <nav class="lg:block"  :class="showMenu ? 'block' : 'hidden'">
                <ul class="flex items-start lg:items-center md:justify-center lg:gap-4 navegation">
                    <li class="split-text">
                        <router-link :to="{ name: 'inicio'}" 
                        class="link">
                        <span class="ico-mbl material-icons-outlined">cottage</span>
                        <span class="text-wrapper"><span class="letters">Inicio</span></span>
                        </router-link>
                    </li>
                    <li class="split-text">
                        <router-link :to="{ name: 'sobre-nosotros'}" 
                        class="link">
                        <span class="ico-mbl material-icons-outlined">grading</span>
                        <span class="text-wrapper"><span class="letters">Quiénes&nbsp;Somos</span></span>
                        </router-link>
                    </li>
                    
                    <li class="split-text" v-click-away="onClickOutside">
                        <button @click="MenuMultiLevel"
                        class="link is-parent" :class="{ 'router-link-active': solutionLinkActive }">
                            <span class="ico-mbl material-icons-outlined">settings_applications</span>
                            <span class="text-wrapper"><span class="letters">Soluciones</span></span>
                            <span class="ico-drop material-icons-outlined !text-base !hidden lg:!inline-block">expand_more</span>
                            <span class="ico-mbl !text-2xl  material-icons-outlined ml-auto lg:!hidden">east</span>
                        </button>
                        <!-- Submenu -->
                        <transition
                            enter-active-class="duration-300 ease-in"
                            enter-from-class="transform opacity-0 -translate-y-8 transition duration-150"
                            enter-to-class="opacity-100 translate-y-0"
                            leave-active-class="duration-200 ease-out"
                            leave-from-class="opacity-100 translate-y-0"
                            leave-to-class="transform opacity-0 -translate-y-8"
                        >
                        <div class="wrapper-submenu transition-all duration-300 ease-in-out">
                        <ul class="submenu" :class="{ isActive: submenu }" v-show="submenu">
                            <li class="lg:hidden">
                                <button @click="submenu = !submenu" class="link font-outfit font-bold text-base uppercase tracking-widest inline-flex items-center mb-4 !border-0"><span class="ico-mbl !text-2xl material-icons-outlined">west</span>Back</button>
                            </li>
                            <li>
                                <router-link :to="{ name: 'servicios-profesionales'}" @click="submenu=false"
                                    class="link">
                                    <span class="thumb hidden img-animated reduced ease-in-out duration-300">
                                        <img src="@/assets/images/menu-serviciosprofesionales.jpg" alt="Servicios Profesionales">
                                    </span>
                                    <span class="ico-mbl material-icons-outlined">api</span>
                                    <span class="text-wrapper">Servicios Profesionales</span>
                                </router-link>
                            </li>

                            <li>
                                <router-link :to="{ name: 'optimizacion-contact-center'}" @click="submenu=false"
                                    class="link">
                                    <span class="thumb hidden img-animated reduced ease-in-out duration-300">
                                        <img src="@/assets/images/menu-callcenter.jpg" alt="Optimización del Contact Center">
                                    </span>
                                    <span class="ico-mbl material-icons-outlined">track_changes</span>
                                    <span class="text-wrapper">Optimización del Contact Center</span>
                                </router-link>
                            </li>

                            <li>
                                <router-link :to="{ name: 'seguridad'}" @click="submenu=false"
                                    class="link">
                                    <span class="thumb hidden img-animated reduced ease-in-out duration-300">
                                        <img src="@/assets/images/menu-seguridad.jpg" alt="Seguridad">
                                    </span>
                                    <span class="ico-mbl material-icons-outlined">center_focus_strong</span>
                                    <span class="text-wrapper">Seguridad</span>
                                </router-link>
                            </li>

                            <li>
                                <router-link :to="{ name: 'analisis-integracion-data'}"  @click="submenu=false"
                                    class="link">
                                    <span class="thumb hidden img-animated reduced ease-in-out duration-300">
                                        <img src="@/assets/images/menu-integraciondata.jpg" alt="Integración de Datos">
                                    </span>
                                    <span class="ico-mbl material-icons-outlined">data_exploration</span>
                                    <span class="text-wrapper">Análisis e Integración de Datos</span>
                                </router-link>
                            </li>

                        </ul>
                        </div>
                        </transition>
                        <!-- End Submenu -->
                    </li>
                    <li class="split-text">
                        <router-link :to="{ name: 'trabaja-en-tein'}" 
                        class="link">
                            <span class="ico-mbl material-icons-outlined">workspace_premium</span>
                            <span class="text-wrapper"><span class="letters">Trabaja&nbsp;en&nbsp;Tein</span></span>
                        </router-link>
                    </li>
                </ul>
            </nav>
            
            
            <!-- Access menu -->
            <ul class="flex-wrap md:justify-end w-3/12 md:w-2/12 hidden lg:w-auto lg:flex secondary-nav">
                <li class="">
                    <router-link :to="{ name: 'contacto'}" class="btn-waves full-color">
                        <span class="relative z-[2]">Contacto</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </header>
    </div>
</template>

<script>
import { ref } from 'vue';
const tailwindConfig = require('../../../tailwind.config.js');
import anime from 'animejs';
export default {
    name: 'HeaderNavegation',
    watch: {
        '$route'() {
            this.showMenu = false;

            if(this.$route.path === '/soluciones/servicios-profesionales' || this.$route.path === '/soluciones/optimizacion-contact-center' || this.$route.path === '/soluciones/seguridad' || this.$route.path === '/soluciones/analisis-integracion-datos') {
                this.solutionLinkActive = true;
                
            } else {
                this.solutionLinkActive = false;
                
            }
        },

    },
    data() {
        return {
            isSticky: false,
            stickyClass: 'is-sticky',
            scrollPosition: 0,
            showSubmenu: false,
            isToggle: false,
            submenu: false,
            windowWidth: 0,
            menuOpen: false,
            lgBreakpoint: Number(tailwindConfig.theme.screens.lg.replace('px', '')),
            solutionLinkActive: false 
        }
    },
    computed: {
        menuVisible() {
        return this.windowWidth >= this.lgBreakpoint ? true : this.menuOpen;

    }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);

        window.addEventListener('resize', this.handleView);
    },
    beforeUnmount() {
        window.addEventListener('resize', this.handleView);
    },
    methods: {  
        updateWindowSize() {
        this.windowWidth = window.innerWidth
        },
        clickMenu() {
        this.menuOpen = !this.menuOpen
        },
        handleScroll() {
            this.scrollPosition = window.scrollY
            if(this.scrollPosition >= 85) {
                this.isSticky = true;
                this.submenu = false;
                this.menuOpen = false;
                this.showMenu = false;
            } else {
                this.isSticky = false;
            }
        },
        mouseoverSubmenu: function(){
            this.menuOpen = true
        },    
        mouseleaveSubmenu: function(){
            this.menuOpen = false
        },

        headerAnimation() {
            let header = anime.timeline({
                loop: false,
                autoplay: true
            });
            header.add({
                targets: '.nagevation li',
                opacity: [0, 1],
                translateX: ['-100px', 0],
                easing: 'easeOutCirc',
                duration: function(el, i) {
                return 250 + (i * 250);
                }
            })
            header.add({
                targets: '.square',
                opacity: [0, 1],
                translateX: [-50, 0],
                easing: 'easeInElastic(1, .6)',
                delay: function (_, i) {
                return i * 100}
            });

        },

        toggleMenu: function() {
            this.isToggle = !this.isToggle;
            this.submenu = false;
        },

        handleView() {
            if (window.innerWidth <= 1023) {
                this.isToggle = true
            } else {
                this.isToggle = false
            }
        },
        MenuMultiLevel() {
            this.submenu = !this.submenu;
            this.showMenu = true;
        },
        onClickOutside() {
            this.submenu = false;
            this.menuOpen = false;
            
        },
    },
    setup() {
        let showMenu = ref(false);
        const toggleNav = () => (showMenu.value = !showMenu.value);
        return { showMenu, toggleNav };
    },
    mounted () {
        this.handleScroll();
        this.updateWindowSize();
        window.addEventListener('resize', this.updateWindowSize);

    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.updateWindowSize);
    },
}
</script>
