<template>
    <div class="footer">
        <!-- Footer -->
        <footer class="pt-12 md:pt-16 lg:pt-20 text-white bg-animated flex flex-col justify-center">
            <div class="container flex flex-col lg:flex-row">
                <!-- Logo -->
                <div class="w-full lg:w-2/12 xl:w-3/12">
                    <a href="#"><img src="@/assets/images/tein-white.svg" alt="tein" class="w-[80px] md:w-[120px] lg:w-44 h-auto fill-blue-100"></a>
                </div>

                <!-- Address -->
                <div class="w-full lg:w-10/12 xl:w-9/12 text-white grid grid-cols-1 sm:grid-cols-3  md:grid-cols-3 gap-5 lg:gap-9 mt-10 lg:mt-0">
                    <div class="mb-4 sm:mb-0">
                        <h3 class="uppercase font-bold tracking-wider mb-3 md:mb-4 text-xs md:text-sm">Argentina ~ C.A.B.A</h3>
                        <address class="not-italic text-sm tracking-wider">
                            Av. del los Incas 4107 <br>
                            Piso 2 - Oficina 13 <br>

                            <a href="mailto:info@tein.com.ar" class="lnk-underline text-white inline-block text-sm font-semibold mt-4">info@tein.com.ar</a>

                        </address>
                    </div>

                    <div class="mb-4 sm:mb-0">
                        <h3 class="uppercase font-bold tracking-wider mb-3 md:mb-4 text-xs md:text-sm">Chile ~ Las Condes</h3>
                        <address class="not-italic text-sm tracking-wider">
                            El Trovador 4280 <br>
                            Oficina 1205 <br>

                            <a href="mailto:info@teingroup.com" class="lnk-underline text-white inline-block text-sm font-semibold mt-4">info@teingroup.com</a>

                        </address>
                    </div>

                    <div class="mb-4 sm:mb-0">
                        <h3 class="uppercase font-bold tracking-wider mb-3 md:mb-4 text-xs md:text-sm">Chile ~ Santiago Centro</h3>
                        <address class="not-italic text-sm tracking-wider">
                            Morandé 835 <br>
                            Oficina 1013 <br>

                            <a href="mailto:info@teingroup.com" class="lnk-underline text-white inline-block text-sm font-semibold mt-4">info@teingroup.com</a>

                        </address>
                    </div>
                </div>
            </div>

            <!-- Links -->
                <!-- <div class="container py-11 md:py-14 lg:py-20 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 lg:gap-9">
                    
                    <div class="lg:col-start-2">
                        <h4 class="text-xs md:text-sm font-bold uppercase tracking-wider mb-3 md:mb-4">Soluciones</h4>
                        <ul class="navfooter">
                            <li><a href="#">Servicios Profesionales</a></li>
                            <li><a href="#">Optimización del Contact Center</a></li>
                            <li><a href="#">Seguridad</a></li>
                            <li><a href="#">Análisis e Integración de Datos</a></li>
                        </ul>
                    </div>

                    <div>
                        <h4 class="text-xs md:text-sm font-bold uppercase tracking-wider mb-3 md:mb-4">Empresa</h4>
                        <ul class="navfooter">
                            <li><a href="#">Inicio</a></li>
                            <li><a href="#">Quienes Somos</a></li>
                            <li><a href="#">Trabaja en Tein</a></li>
                            <li><a href="#">Contacto</a></li>
                        </ul>
                    </div>

                    <div>
                        <h4 class="text-xs md:text-sm font-bold uppercase tracking-wider mb-3 md:mb-4">Accesos</h4>
                        <ul class="navfooter">
                            <li><a href="#" class="inline-flex items-center">Clientes <span class="material-icons-outlined !text-sm ml-1">arrow_outward</span></a></li>
                            <li><a href="#" class="inline-flex items-center">Equipo Tein <span class="material-icons-outlined !text-sm ml-1">arrow_outward</span></a></li>
                        </ul>
                    </div>

                </div> -->

            <!-- Copyright -->
            <div class="container py-5 md:py-7 mt-5 md:mt-9 lg:mt-12 border-t border-white/25 flex flex-col sm:flex-row sm:justify-between sm:items-center">
                <div class="flex items-center">
                    <p class="font-rubik text-sm font-semibold inline-block mb-0 pt-[2px]">&copy; tein 2023 </p>
                    <router-link :to="{ name: 'legal' }">
                    <span class="uppercase font-rubik text-xs font-semibold inline-block tracking-wider cursor-pointer"><span class="inline-block px-4 text-xs font-light opacity-25">|</span><span class="lnk-underline">Legal</span></span>
                    </router-link>
                </div>
                <ul class="listSocialMedia flex order-first mb-5 sm:mb-0  sm:order-2 items-center">
                    <!-- Facebook -->
                    <li class="ml-4"><a href="https://www.facebook.com/teinargentina" target="_blank" class="w-9 h-9  transition duration-150 rounded-full border border-white flex justify-center items-center"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M9.5 3H12V0H9.5C7.57 0 6 1.57 6 3.5V5H4v3h2v8h3V8h2.5l.5-3H9V3.5c0-.271.229-.5.5-.5z"/></svg></a></li>
                    <!-- Instagram -->
                    <li class="ml-4"><a href="https://www.instagram.com/teingroup/" target="_blank" class="w-9 h-9 transition duration-150 rounded-full border border-white flex justify-center items-center"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M14.5 0h-13C.675 0 0 .675 0 1.5v13c0 .825.675 1.5 1.5 1.5h13c.825 0 1.5-.675 1.5-1.5v-13c0-.825-.675-1.5-1.5-1.5zM11 2.5c0-.275.225-.5.5-.5h2c.275 0 .5.225.5.5v2c0 .275-.225.5-.5.5h-2a.501.501 0 0 1-.5-.5v-2zM8 5a3.001 3.001 0 0 1 0 6a3.001 3.001 0 0 1 0-6zm6 8.5c0 .275-.225.5-.5.5h-11a.501.501 0 0 1-.5-.5V7h1.1A4.999 4.999 0 0 0 8 13a4.999 4.999 0 0 0 4.9-6H14v6.5z"/></svg></a></li>
                    <!-- Linkedin -->
                    <li class="ml-4"><a href="https://www.linkedin.com/company/tein---tecnolog-a-e-innovaci-n-s-a-/?trk=tyah" target="_blank" class="w-9 h-9 transition duration-150 rounded-full border border-white flex justify-center items-center"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="18px" height="18px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M9.429 8.969h3.714v1.85c.535-1.064 1.907-2.02 3.968-2.02c3.951 0 4.889 2.118 4.889 6.004V22h-4v-6.312c0-2.213-.535-3.461-1.897-3.461c-1.889 0-2.674 1.345-2.674 3.46V22h-4V8.969ZM2.57 21.83h4V8.799h-4V21.83ZM7.143 4.55a2.53 2.53 0 0 1-.753 1.802a2.573 2.573 0 0 1-1.82.748a2.59 2.59 0 0 1-1.818-.747A2.548 2.548 0 0 1 2 4.55c0-.677.27-1.325.753-1.803A2.583 2.583 0 0 1 4.571 2c.682 0 1.336.269 1.819.747c.482.478.753 1.126.753 1.803Z" clip-rule="evenodd"/></svg></a></li>
                    <!-- Youtube -->
                    <li class="ml-4"><a href="https://www.youtube.com/@tein7730" target="_blank" class="w-9 h-9 transition duration-150 rounded-full border border-white flex justify-center items-center"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="19px" height="19px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M941.3 296.1a112.3 112.3 0 0 0-79.2-79.3C792.2 198 512 198 512 198s-280.2 0-350.1 18.7A112.12 112.12 0 0 0 82.7 296C64 366 64 512 64 512s0 146 18.7 215.9c10.3 38.6 40.7 69 79.2 79.3C231.8 826 512 826 512 826s280.2 0 350.1-18.8c38.6-10.3 68.9-40.7 79.2-79.3C960 658 960 512 960 512s0-146-18.7-215.9zM423 646V378l232 133l-232 135z"/></svg></a></li>
                </ul>
            </div>
        </footer>
    </div>
</template>