module.exports = {
  content: ['./index.html', './src/**/*.{vue,html,js,ts,jsx,tsx}'],
  theme: {
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px'
    },
    container: {
      center: true,
    },
    fontFamily: {
      'body': ['Outfit', 'sans-serif'],
      'title': ['Oswald', 'sans-serif'],
      'abril': ['Rubik', 'sans-serif'],
      'rubik': ['Rubik', 'sans-serif'],
      'barlow': ['Barlow', 'sans-serif'],
      'outfit': ['Outfit', 'sans-serif'],
      'oswald': ['Oswald', 'sans-serif'],
      'jost': ['Jost', 'sans-serif'],
      'worksans': ['Work Sans', 'sans-serif']

    },
    extend: {
      colors: {
        greentein       : '#9BBE3C',
        bluetein        : '#004E82',
        limatein        : '#E0E31C',
        redtein         : '#F04138',
        orangetein      : '#F58228',
        lightorangetein : '#FCB527',
        blacktein       : '#0F172A',
        graytein        : '#3c3c3c',
        'transparent'   : 'transparent'
      },
      zIndex: {
        '-1': '-1',
        '1': '1',
      },
    },
  },
  plugins: [
    require("@tailwindcss/forms")({
      strategy: 'class',
    }),
  ],
}
